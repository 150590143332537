<template>
  <v-container
    v-if="settings"
    class="pa-0 ma-0"
    fill-height
    fluid
    style="background-color: white;"
  >
    <v-overlay
      absolute
      :value="loading"
    >
      <v-progress-circular
        :size="50"
        color="white"
        indeterminate
      />
    </v-overlay>

    <!-- TABS -->
    <v-tabs
      v-if="!loadingTabs"
      v-model="tab"
      centered
      show-arrows
      :slider-color="account.accountType !== 'lawyer' ? 'white' : 'accent'"
      class="sticky-tabs"
      @change="setTab(tab)"
    >
      <v-tab
        :style="account.accountType !== 'lawyer' ? 'color: black; opacity: 1.0; pointer-events: none;' : ''"
      >
        {{ account.accountType === 'lawyer' ? $t('templates|template') : $t('documents|document') }}
      </v-tab>
      <v-tab v-if="account.accountType === 'lawyer'">
        {{ $t('questionnaire|questionnaire') }}
      </v-tab>
      <v-tab v-if="options.inCase && account.accountType === 'lawyer'">
        {{ $t('cases|filling_assistant') }}
      </v-tab>
    </v-tabs>

    <v-tabs-items
      v-model="tab"
      class="full-height-tab"
    >
      <!-- EDITOR -->
      <v-tab-item>
        <EditorMobile
          ref="editor"
          :tab="tab"
          :edit="options.inCase || options.inTemplate"
          :language="templateData.language"
          :questionnaire="templateData.questionnaire"
          :template-field-tags="templateFieldTags"
          :related-documents="workbench ? workbench.assignedDocuments : []"
          :template-id="templateData._id"
          :template="templateData"
          :workbench="workbench"
          :case-data="caseData"
          :current-case-form="currentCaseForm"
          :account="account"
          :options="options"
          @refreshQuestionairreFields="refreshTab"
          @saveSuggestion="saveSuggestion"
          @loading="loading = true"
          @stop-loading="loading = false"
          @update-template-name="updateTemplateName"
          @update-template-description="updateTempDescription"
          @closeTemplate="$emit('closeTemplate')"
        />
      </v-tab-item>

      <!-- QUESTIONNAIRE -->
      <v-tab-item
        v-if="account.accountType === 'lawyer'"
        :key="componentKey"
        :style="`background-color: #eee; height: ${$vuetify.breakpoint.height}`"
      >
        <v-container
          v-if="!addNewFieldPage && !inPreview && !questionnaireRightSidePanelPage && !questionnaireLeftSidePanelPage"
          fluid
          :style="`min-height: ${$vuetify.breakpoint.height - 96 + 'px'};`"
          class="pa-6"
        >
          <v-card
            color="lightBackground"
            width="100%"
            class="toolbar"
            flat
          >
            <v-btn
              icon
              class="ml-2"
              @click="$emit('closeTemplate')"
            >
              <v-icon>
                mdi-arrow-left
              </v-icon>
            </v-btn>

            <v-btn
              icon
              class="active"
              @click="openAddField"
            >
              <v-icon>mdi-plus-circle-outline</v-icon>
            </v-btn>

            <v-btn
              icon
              class="active"
              @click="inPreview = true"
            >
              <v-icon>mdi-eye</v-icon>
            </v-btn>

            <v-btn
              v-if="options.inCase && !templateData.questionnaire.sharedWithClient"
              icon
              class="active"
              @click="$emit('share', templateData.questionnaire)"
            >
              <v-icon>mdi-share-variant</v-icon>
            </v-btn>

            <v-btn
              v-if="options.inCase && templateData.questionnaire.sharedWithClient"
              icon
              class="active"
              @click="$emit('stop-sharing', templateData.questionnaire);"
            >
              <v-icon>mdi-share-off</v-icon>
            </v-btn>

            <v-btn
              icon
              class="active"
              @click="questionnaireRightSidePanelPage = true"
            >
              <v-icon>mdi-file-outline</v-icon>
            </v-btn>

            <v-btn
              icon
              class="active"
              @click="questionnaireLeftSidePanelPage = true"
            >
              <v-icon>mdi-at</v-icon>
            </v-btn>
          </v-card>
          <v-row
            class="mt-6"
          >
            <v-col
              cols="12"
            >
              <!-- QUESTIONNAIRE INFO -->
              <v-card
                class="mb-2"
              >
                <v-card-title>
                  <v-row
                    no-gutters
                    dense
                  >
                    <v-col
                      cols="2"
                      class="text-center"
                    >
                      <img
                        src="@/assets/Lavvira_monogramm_logo.svg"
                        height="35px"
                        width="auto"
                        class="mr-2 mt-2"
                      >
                    </v-col>
                    <v-col cols="10">
                      <div style="word-break: keep-all;">
                        {{ $t('questionnaire|lavvira_questionnaire_for_template') }}: {{ templateData.name }}
                      </div>
                    </v-col>
                  </v-row>
                </v-card-title>
                <v-card-text v-if="options.inCaseModel">
                  <div>
                    {{ $t('case_models|case_model') }}: {{ currentCaseForm.name }}
                  </div>
                  <div>
                    {{ $t('case_models|workbench') }}: {{ workbench.name }}
                  </div>
                </v-card-text>
                <v-card-text v-if="options.inCase">
                  <div>
                    {{ $t('cases|case') }}: {{ caseData.caseName }}
                  </div>
                  <div>
                    {{ $t('cases|workbench') }}: {{ workbench.name }}
                  </div>
                  <div v-if="templateData.questionnaire.sharedWithClient">
                    {{ $t('common|status') }}: {{ $t('status|sent_to_client') }}
                  </div>
                  <div v-if="!templateData.questionnaire.sharedWithClient">
                    {{ $t('common|status') }}: {{ $t('status|not_sent_to_client') }}
                  </div>
                </v-card-text>
              </v-card>

              <!-- QUESTIONNAIRE TITLE -->
              <v-card class="mt-4">
                <v-card-text>
                  <v-textarea
                    v-model="templateData.questionnaire.title"
                    :label="$t('common|title')"
                    :placeholder="$t('expressions|sir_madam')"
                    persistent-placeholder
                    @blur="updateQuestionnaireTitle(templateData.questionnaire.title)"
                  />
                </v-card-text>
              </v-card>

              <!-- QUESTIONNAIRE FIELDS -->
              <draggable
                v-model="templateData.questionnaire.fields"
                :animation="200"
                group="questionnaire.fields"
                ghost-class="ghost-card"
                handle=".handle-icon"
                style="width: 100%;"
                @start="drag=true"
                @end="drag=false; $emit('change-field-order', templateData.questionnaire.fields)"
              >
                <template v-for="field in templateData.questionnaire.fields">
                  <QuestionnaireFieldEdit
                    :key="field.id"
                    :field="translateQuestionnaireField(field)"
                    :exclude-fields="templateFieldTags"
                    :active-language="templateData.language"
                    @save-questionnaire="saveTemplate"
                    @fieldChanged="updateField"
                    @preview="inPreview = true"
                    @deleteField="deleteField"
                  />
                </template>
              </draggable>
            </v-col>
          </v-row>
        </v-container>

        <!-- QUESTIONNAIRE PREVIEW -->
        <v-container
          v-else-if="templateData && inPreview && !questionnaireRightSidePanelPage"
          class="pa-0"
        >
          <v-card
            color="lightBackground"
            width="100%"
            class="toolbar"
            flat
          >
            <v-btn
              icon
              class="ml-2"
              @click="inPreview = false"
            >
              <v-icon>
                mdi-arrow-left
              </v-icon>
            </v-btn>
          </v-card>
          <v-card>
            <v-card-title>
              <img
                src="@/assets/Lavvira_monogramm_logo.svg"
                height="28px"
                width="auto"
              >
              <div
                class="ml-3"
              >
                {{ $t('questionnaire|questionnaire') }}
              </div>
            </v-card-title>
            <v-card-text
              class="pt-4"
              style="height: 70vh;"
            >
              <v-card
                v-if="templateData.questionnaire.title"
                min-height="100"
                outlined
                class="mx-auto mb-10 rounded-tl-lg rounded-tr-lg rounded-br-lg rounded-bl-lg"
              >
                <v-card-text
                  class="pa-2"
                >
                  {{ templateData.questionnaire.title }}
                </v-card-text>
              </v-card>
              <!--Questionnaire preview for lawyer! -->
              <!-- Questionnaire answers dialog is QuestionnaireAnswerDialog in WorkbenchView -->
              <QuestionnaireForm
                :questionnaire="templateData.questionnaire"
                @update="updateQuestionnaire"
              />
            </v-card-text>
          </v-card>
        </v-container>

        <v-container
          v-else-if="addNewFieldPage && !inPreview && !questionnaireRightSidePanelPage && !questionnaireLeftSidePanelPage"
          :style="`min-height: ${$vuetify.breakpoint.height - 96 + 'px'}; background-color: white;`"
        >
          <v-card
            color="lightBackground"
            width="100%"
            class="toolbar"
            flat
          >
            <v-btn
              icon
              class="ml-2"
              @click="addNewFieldPage = false"
            >
              <v-icon>
                mdi-arrow-left
              </v-icon>
            </v-btn>
          </v-card>

          <v-stepper
            v-model="e6"
            vertical
            flat
            class="mt-4"
          >
            <v-stepper-step
              :complete="e6 > 1"
              step="1"
            >
              {{ $t('questionnaire|select_question_type') }}
              <small v-if="selectedFieldType">{{ getFieldType(selectedFieldType) }}</small>
            </v-stepper-step>

            <v-stepper-content step="1">
              <v-card
                color="grey lighten-1"
                flat
                min-height="150px"
                class="pa-0"
              >
                <v-list
                  v-model="selectedFieldType"
                >
                  <v-list-item
                    v-for="(fieldType, index) in fieldTypes"
                    :key="index"
                    class="pa-0"
                    @click="e6++; selectedFieldType = fieldType"
                  >
                    <v-icon
                      small
                      color="primary"
                      class="mr-2"
                    >
                      {{ getIconType(fieldType) }}
                    </v-icon>
                    <v-list-item-content>
                      <v-list-item-title style="white-space: normal;">
                        {{ getFieldType(fieldType) }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-card>
            </v-stepper-content>

            <v-stepper-step
              :complete="e6 > 2"
              step="2"
            >
              {{ $t('actions|select_content') }}
            </v-stepper-step>

            <v-stepper-content step="2">
              <v-card
                flat
                class="mb-12 pa-0"
                :height="$vuetify.breakpoint.height - 390"
              >
                <v-text-field
                  v-model="searchText"
                  class="mt-2"
                  prepend-inner-icon="mdi-magnify"
                  :label="$t('common|search')"
                  dense
                />
                <v-menu offset-y>
                  <template v-slot:activator="{ on, attrs }">
                    <v-chip
                      outlined
                      small
                      :attrs="attrs"
                      v-on="on"
                    >
                      {{ $t('actions|change_language') }}
                      <v-icon>
                        mdi-menu-down
                      </v-icon>
                    </v-chip>
                  </template>
                  <v-list>
                    <v-list-item
                      v-for="(lang, index) in languages"
                      :key="index"
                      @click="setLanguage(lang)"
                    >
                      <v-list-item-title>{{ lang }}</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
                <v-list
                  :height="$vuetify.breakpoint.height - 450"
                  style="overflow-y: auto"
                  class="mt-3 mb-n2"
                >
                  <v-list-item
                    v-for="(field, i) in fieldsForDialog"
                    :key="i"
                    :disabled="field.disabled"
                    @click="addNewField(field)"
                  >
                    <v-list-item-content>
                      <v-list-item-title
                        v-if="!field.disabled"
                        style="white-space: pre-wrap; word-break: keep-all;"
                      >
                        {{ field.name }}
                      </v-list-item-title>
                      <v-list-item-title
                        v-if="field.disabled"
                        style="white-space: pre-wrap; word-break: keep-all;"
                      >
                        {{ field.name }}
                        <v-chip
                          x-small
                          color="#5694d6"
                        >
                          {{ $t('common|in_use') }}
                        </v-chip>
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-card>
              <v-btn
                v-if="e6 !== 1"
                text
                style="position: absolute; bottom: 5px;"
                @click="selectedFieldType = null; e6--"
              >
                <v-icon
                  small
                  class="mr-2"
                >
                  mdi-undo
                </v-icon>
                {{ $t('actions|back') }}
              </v-btn>
            </v-stepper-content>
          </v-stepper>
        </v-container>

        <!-- QUETSIONNAIRE RIGHT SIDE PANEL ON DESKTOP -> HERE NEW PAGE/SECTION -->
        <v-container
          v-if="questionnaireRightSidePanelPage"
          class="px-0"
          style="background-color: white;"
        >
          <v-card
            color="lightBackground"
            width="100%"
            class="toolbar"
            flat
          >
            <v-btn
              icon
              class="ml-2"
              @click="questionnaireRightSidePanelPage = false"
            >
              <v-icon>
                mdi-arrow-left
              </v-icon>
            </v-btn>
            <v-icon
              color="primary"
            >
              mdi-file-outline
            </v-icon>
            {{ $t('documents|required_documents') }}
          </v-card>
          <QuestionnaireSuggestionsPanelMobile
            v-if="templateData"
            :template-data="templateData"
            :workbench="workbench"
            :options="{
              hasClose: false,
              inCase: options.inCase,
              inCaseModel: options.inCaseModel,
              inTemplate: options.inTemplate,
              isVisibleDocuments: true,
              isVisibleSuggestions: false
            }"
            @addField="addNewQuestionnaireFieldInCase"
            @addAllUnusedFields="addNewFieldsInCaseQuestionnaire"
            @updateField="saveTemplate"
            @deleteField="deleteFieldInCaseQuestionnaire"
            @updateQuestionnaire="updateQuestionnaire"
            @add-new-document-in-template-questionnaire="onAddNewFileInputSuggestion"
            @share="shareTemplate"
            @stop-sharing="stopSharingQuestionnaire"
          />
        </v-container>

        <!-- QUETSIONNAIRE LEFT SIDE PANEL ON DESKTOP -> HERE NEW PAGE/SECTION -->
        <v-container
          v-if="questionnaireLeftSidePanelPage"
          class="px-0"
          style="background-color: white;"
        >
          <v-card
            color="lightBackground"
            width="100%"
            class="toolbar"
            flat
          >
            <v-btn
              icon
              class="ml-2"
              @click="questionnaireLeftSidePanelPage = false"
            >
              <v-icon>
                mdi-arrow-left
              </v-icon>
            </v-btn>
            <v-icon
              color="primary"
            >
              mdi-at
            </v-icon>
            {{ $t('mixed|suggestions') }}

            <v-menu
              offset-y
            >
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  class="mt-n1"
                  color="primary"
                  v-bind="attrs"
                  v-on="on"
                >
                  mdi-information-variant
                </v-icon>
              </template>
              <v-card
                max-width="400"
              >
                <v-card-text>
                  {{ $t('expressions|if_you_want_to_change_the_questions_language') }}
                </v-card-text>
              </v-card>
            </v-menu>
          </v-card>
          <QuestionnaireSuggestionsPanelMobile
            v-if="templateData"
            :template-data="templateData"
            :workbench="workbench"
            :options="{
              hasClose: false,
              inCase: options.inCase,
              inCaseModel: options.inCaseModel,
              inTemplate: options.inTemplate,
              isVisibleDocuments: false,
              isVisibleSuggestions: true
            }"
            @addField="addNewQuestionnaireFieldInCase"
            @addAllUnusedFields="addNewFieldsInCaseQuestionnaire"
            @updateField="saveTemplate"
            @deleteField="deleteFieldInCaseQuestionnaire"
            @updateQuestionnaire="updateQuestionnaire"
            @add-new-document-in-template-questionnaire="onAddNewFileInputSuggestion"
            @share="shareTemplate"
            @stop-sharing="stopSharingQuestionnaire"
          />
        </v-container>
      </v-tab-item>

      <v-tab-item>
        <FillingAssistentPanelMobile
          v-if="templateData"
          :edit="true"
          :language="templateData.language"
          :questionairre="templateData.questionnaire"
          :related-documents="workbench ? workbench.assignedDocuments : []"
          :template-id="templateData._id"
          :template="templateData"
          :workbench="workbench"
          :case-data="caseData"
          :account="account"
        />
      </v-tab-item>
    </v-tabs-items>
  </v-container>
</template>

<script>
import { mapActions, mapState, mapMutations } from 'vuex'
import EditorMobile from '../Editor/EditorMobile.vue'
import QuestionnaireFieldEdit from '@/components/forms/QuestionnaireFieldEdit/QuestionnaireFieldEdit.vue'
import QuestionnaireForm from '@/components/forms/QuestionnaireFieldEdit/QuestionnaireForm.vue'
import QuestionnaireSuggestionsPanelMobile from './QuestionnaireSuggestionPanelMobile.vue'
import FillingAssistentPanelMobile from './FillingAssistentPanelMobile.vue'
import fieldTypes from '@/enums/fieldTypes'
import { v4 as uuidv4 } from 'uuid'
import draggable from 'vuedraggable'
import { EventBus } from '@/utils/EventBus'
import generalMixin from '@/utils/generalMixin'
import templateViewMixin from '../../Cases/CaseMixins/templateViewMixin'

export default {
  components: {
    EditorMobile,
    QuestionnaireFieldEdit,
    QuestionnaireForm,
    QuestionnaireSuggestionsPanelMobile,
    FillingAssistentPanelMobile,
    draggable
  },
  mixins: [
    generalMixin,
    templateViewMixin
  ],
  props: {
    options: {
      type: Object,
      default: () => ({
        hasClose: false,
        inCase: false,
        inCaseModel: false,
        inTemplate: false
      })
    },
    currentCaseForm: {
      type: Object,
      default: null
    },
    workbench: {
      type: Object,
      default: null
    },
    templateData: {
      type: Object,
      default: null
    },
    caseData: {
      type: Object,
      default: null
    },
    tabChange: {
      type: Number,
      default: 0
    },
    scrollToElement: {
      type: Object,
      default: null
    }
  },
  data () {
    return {
      tab: null,
      error: null,
      loading: true,
      loadingTabs: true,
      editor: null,
      templateEditorTab: 0,
      addNewFieldPage: false,
      fieldTypes,
      selectedFieldType: null,
      inPreview: false,
      componentKey: 0,
      componentKeyAssistent: 0,
      componentKeyEditor: 0,
      actualTemplateData: {},
      e6: 1,
      nodes: [],
      searchText: '',
      questionnaireRightSidePanelPage: false,
      questionnaireLeftSidePanelPage: false,
      selectedLanguage: localStorage.getItem('QuestionnaireFieldsLanguage') ||
      localStorage.getItem('preferedLanguage') ||
      'en'

    }
  },
  computed: {
    ...mapState({
      account: state => state.account.account,
      settings: state => state.settings.settings,
      templates: state => state.templates.templates,
      actualSuggestionsEditorStorage: state => state.editorStore.actualSuggestionsEditorStorage
    }),
    onTabChange () {
      return this.tabChange
    },
    fieldsForDialog () {
      if (!this.settings || !this.settings.fields || !this.selectedFieldType) return []
      const arr = Object.keys(this.settings.fields).reduce((acc, key) => {
        let name = this.settings.fields[key].label[this.selectedLanguage] ?? undefined
        if (Array.isArray(this.settings.fields[key].label[this.selectedLanguage])) {
          name = this.settings.fields[key].label[this.selectedLanguage].join(' / ')
        }
        const _key = key.toLowerCase()
        const disabled = this.templateFieldTags.some(f => f.key === _key)
        const field = this.settings.fields[key]
        if (field.type === this.selectedFieldType) {
          acc.push({
            id: uuidv4(),
            name: name,
            fieldType: this.settings.fields[key].type,
            fieldKey: _key,
            disabled: disabled,
            language: this.selectedLanguage || 'en'
          })
        }
        return acc
      }, [])
      const removeUndefined = (obj) => {
        for (var prop in obj) {
          if (obj.hasOwnProperty(prop) && obj[prop] === undefined) {
            return
          }
        }
        return obj
      }
      let filteredArr = arr.filter(removeUndefined)
      const filterItems = (arr, query) => {
        return arr.filter((el) => {
          if (Array.isArray(el.name)) {
            el.name.forEach(_name => {
              return _name.toLowerCase().indexOf(query.toLowerCase()) !== -1
            })
          } else {
            if (el.name) return el.name.toLowerCase().includes(query.toLowerCase())
          }
        })
      }
      if (this.searchText && this.searchText.length) {
        filteredArr = filterItems(arr, this.searchText)
      }
      return filteredArr
    },
    languages () {
      let activeLanguages = this.settings.activeLanguages
      return activeLanguages
    },
    actualRoute () {
      let route
      if (this.options.inCase) {
        route = `/cases/${this.$route.params._id}/${this.workbench._id}`
      }
      return route
    },
    suggestionsFromQuestionnaire () {
      return this.workbench.templates.reduce((acc, s) => {
        acc.push(
          ...s.questionnaire.fields
        )
        return acc
      }, [])
    },
    filteredSuggestionsFromQuestionnaire () {
      let suggestions = []
      for (let i = 0; i < this.suggestionsFromQuestionnaire.length; i++) {
        const suggestion = this.suggestionsFromQuestionnaire[i]
        if (suggestion.fieldType === 'file-input') {
          suggestions.push(suggestion)
        }
      }
      return suggestions
    },
    allSuggestions () {
      const suggestions = this.filteredSuggestionsFromQuestionnaire.concat(this.workbench.requiredDocuments)
      return suggestions
    },
    uniqueWorkbenchRequiredDocs () {
      return this.allSuggestions.reduce((seed, current) => {
        return Object.assign(seed, {
          [current.key]: current
        })
      }, {})
    },
    suggestionsInUseInQuestionnaire () {
      let fieldNames = []
      if (this.currentCaseForm) {
        for (let i = 0; i < this.currentCaseForm.workbenches.length; i++) {
          const workbench = this.currentCaseForm.workbenches[i]
          for (let i = 0; i < workbench.templates.length; i++) {
            const template = workbench.templates[i]
            const fields = template.questionnaire.fields
            for (let i = 0; i < fields.length; i++) {
              const field = fields[i]
              let fieldName = field.name
              fieldNames.push(fieldName)
            }
          }
        }
      }
      if (this.caseData) {
        for (let i = 0; i < this.caseData.workbenches.length; i++) {
          const workbench = this.caseData.workbenches[i]
          for (let i = 0; i < workbench.templates.length; i++) {
            const template = workbench.templates[i]
            const fields = template.questionnaire.fields
            for (let i = 0; i < fields.length; i++) {
              const field = fields[i]
              let fieldName = field.name
              fieldNames.push(fieldName)
            }
          }
        }
      }
      if (this.options.inTemplate) {
        for (let i = 0; i < this.templates.length; i++) {
          const template = this.templates[i]
          const fields = template.questionnaire.fields
          for (let i = 0; i < fields.length; i++) {
            const field = fields[i]
            let fieldName = field.name
            fieldNames.push(fieldName)
          }
        }
      }
      return fieldNames
    },
    templateSuggestions () {
      const suggestions = Object.keys(this.settings.fields).reduce(
        (acc, key) => {
          const item = this.settings.fields[key]
          if (item.type !== 'file-input') {
            acc[key] = item
          }
          return acc
        },
        {}
      )
      return suggestions
    },
    // _suggestions () {
    //   return Object.keys(this.templateSuggestions).map(key => {
    //     return {
    //       id: key,
    //       name: this.templateSuggestions[key].label[this.templateData.language],
    //       fieldType: this.templateSuggestions[key].type,
    //       key: key
    //     }
    //   })
    // },
    // rawTemplateFieldTags () {
    //   if (this.options.inCase) {
    //     return this.actualSuggestionsEditorStorage
    //   } else {
    //     if (!this.templateData.templateData || !this.templateData.templateData.json) return []
    //     const fields = []
    //     const getFieldRecursive = (field, arr) => {
    //       if (field.type === 'mention') {
    //         return arr.push(field)
    //       }
    //       if (field.content) {
    //         field.content.forEach((f) => {
    //           getFieldRecursive(f, arr)
    //         })
    //       }
    //     }
    //     getFieldRecursive(this.templateData.templateData.json, fields)
    //     return fields
    //   }
    // },
    templateFieldTags () {
      const fields = this.actualSuggestionsEditorStorage.map((f) => {
        let key
        if (f.attrs.fieldKey) {
          key = f.attrs.fieldKey.toLowerCase()
        } else {
          key = f.attrs.label.toLowerCase().split(' ').join('_')
        }
        const customHashtag = f.attrs.customHashtag
        const inUse = this.templateData.questionnaire.fields.findIndex(field => {
          if (field.fieldKey && key === field.fieldKey.toLowerCase()) {
            return f.attrs.customLabel === field.customLabel
          } else {
            return false
          }
        })
        return {
          customHashtag,
          fieldType: f.attrs.fieldType,
          fieldKey: f.attrs.fieldKey,
          hint: '',
          id: f.attrs.id,
          customLabel: f.attrs.customLabel,
          // key: key,
          name: f.attrs.label,
          inUse: inUse !== -1,
          language: f.language
        }
      })
      const uniqueFields = fields.reduce((acc, field) => {
        const foundField = acc.find(i => i.fieldKey === field.fieldKey)
        if (foundField) { // already in array
          const foundWithCustomLabel = acc.find(i => i.fieldKey === field.fieldKey && i.customLabel === field.customLabel)
          if (!foundWithCustomLabel) acc.push(field)
        } else {
          acc.push(field)
        }
        return acc
      }, [])
      return uniqueFields
    },
    rawTemplateDocs () {
      if (!this.templateData.questionnaire.fields) return []
      const fields = []
      for (let i = 0; i < this.templateData.questionnaire.fields.length; i++) {
        const field = this.templateData.questionnaire.fields[i]
        if (field.fieldType === 'file-input') {
          fields.push(field)
        }
      }
      return fields
    },
    actualCase () {
      if (this.caseData) return this.caseData
      else return {}
    }
  },
  watch: {
    addNewFieldPage (val) {
      if (!val) {
        this.e6 = 1
        this.selectedFieldType = null
      }
    },
    actualCase (val) {
      if (val) {
        this.componentKeyEditor++
      }
    },
    onTabChange (val) {
      // function trigered from template section
      // case template is onScrollToFieldInQuestionnaire via EventBus
      this.tab = val
      if (this.scrollToElement) {
        setTimeout(() => {
          const element = document.getElementById(this.scrollToElement.id)
          if (element) {
            element.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'nearest' })
            element.style.backgroundColor = this.$vuetify.theme.themes.light.background
            setTimeout(() => {
              element.style.backgroundColor = ''
            }, '1000')
          }
          this.$emit('scroll-success')
        }, 500)
      }
    }
  },
  mounted () {
    // const templateContent =
    //   this.templateData.templateData.json ||
    //   this.templateData.templateData.html
    // if (templateContent) {
    //   this.$refs.editor.setContent(templateContent)
    // } else if (
    //   this.templateData &&
    //   this.templateData.templateDocument &&
    //   this.templateData.templateDocument.parsed
    // ) {
    //   this.$refs.editor.setContent(
    //     this.templateData.templateDocument.parsed.text_plain
    //   )
    // }
  },
  async created () {
    // timeout because of the tab slider => otherwise not centered
    this.$nextTick(() => {
      setTimeout(() => {
        this.loadingTabs = false
        this.loading = false
      }, '300')
    })
    EventBus.$on('suggestion-to-be-updated-in-editor', this.onFieldEvent)
    EventBus.$on('save-comment-in-template', this.saveTemplate)
    EventBus.$on('add-new-file-input-suggestion', this.onAddNewFileInputSuggestion)
    EventBus.$on('scroll-to-field-in-questionnaire', this.onScrollToFieldInQuestionnaire)
  },
  beforeDestroy () {
    EventBus.$off('suggestion-to-be-updated-in-editor', this.onFieldEvent)
    EventBus.$off('save-comment-in-template', this.saveTemplate)
    EventBus.$off('add-new-file-input-suggestion', this.onAddNewFileInputSuggestion)
    EventBus.$off('scroll-to-field-in-questionnaire', this.onScrollToFieldInQuestionnaire)
  },
  methods: {
    ...mapActions({
      addToast: 'toasts/addToast',
      updateCaseWorkbenchTemplateSuggestion: 'cases/updateCaseWorkbenchTemplateSuggestion',
      updateQuestionnaireInCase: 'questionnaire/updateQuestionnaireInCase',
      updateQuestionnaireInTemplate: 'questionnaire/updateQuestionnaireInTemplate',
      updateQuestionnaireInCaseModel: 'questionnaire/updateQuestionnaireInCaseModel'
    }),
    ...mapMutations({
      toggleDrawer: 'app/toggleDrawer'
    }),
    setTab (tab) {
      if (this.options.inTemplate) this.$emit('tab-changed', tab)
      if (this.options.inCase) EventBus.$emit('actual-tab-template-view-cases', tab)
      if (this.options.inCaseModel) EventBus.$emit('actual-tab-template-view-case-models', tab)
    },
    async updateQuestionnaireTitle (newTitle) {
      if (this.options.inCase) {
        try {
          const payload = {
            title: newTitle,
            changeQuestionnaireTitle: true
          }
          const _id = this.templateData.questionnaire._id
          const caseId = this.caseData._id
          const workbenchId = this.workbench._id
          const templateId = this.templateData._id
          await this.updateQuestionnaireInCase({ _id, caseId, workbenchId, templateId, payload })
        } catch (e) {
          console.error(e, 'error')
          this.addToast({
            title: this.$t('warning|questionnaire_update_failed'),
            color: 'white',
            snackbarColor: 'error'
          })
        }
      }
      if (this.options.inTemplate) {
        try {
          const payload = {
            title: newTitle,
            changeQuestionnaireTitle: true
          }
          const _id = this.templateData.questionnaire._id
          const templateId = this.templateData._id
          await this.updateQuestionnaireInTemplate({ _id, templateId, payload })
        } catch (e) {
          console.error(e, 'error')
          this.addToast({
            title: this.$t('warning|questionnaire_update_failed'),
            color: 'white',
            snackbarColor: 'error'
          })
        }
      }
      if (this.options.inCaseModel) {
        try {
          const payload = {
            title: newTitle,
            changeQuestionnaireTitle: true
          }
          const _id = this.templateData.questionnaire._id
          const caseModelId = this.currentCaseForm._id
          const workbenchId = this.workbench._id
          const templateId = this.templateData._id
          await this.updateQuestionnaireInCaseModel({ _id, caseModelId, workbenchId, templateId, payload })
        } catch (e) {
          console.error(e, 'error')
          this.addToast({
            title: this.$t('warning|questionnaire_update_failed'),
            color: 'white',
            snackbarColor: 'error'
          })
        }
      }
    },
    onScrollToFieldInQuestionnaire (suggestion) {
      let element
      if (suggestion.inUse) {
        this.tab = suggestion.tab
        this.setTab(suggestion.tab)
        setTimeout(() => {
          const elId = suggestion.fromSuggestionPanel ? 'questionnaire-field' + '-' + suggestion.id : suggestion.id
          element = document.getElementById(elId)
          if (element) {
            element.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'nearest' })
            element.style.backgroundColor = this.$vuetify.theme.themes.light.background
            setTimeout(() => {
              element.style.backgroundColor = ''
            }, '1500')
          }
        }, 500)
      } else {
        this.tab = suggestion.tab
        this.setTab(suggestion.tab)
        setTimeout(() => {
          element = document.getElementById(suggestion.id)
          if (element) {
            element.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'nearest' })
            element.style.backgroundColor = this.$vuetify.theme.themes.light.background
            element.style.border = 'thin solid red'
            setTimeout(() => {
              element.style.backgroundColor = 'white'
              element.style.border = ''
            }, '2000')
          }
        }, 500)
      }
    },
    onFieldEvent (field) {
      this.updateField(field)
    },
    openAddField () {
      this.addNewFieldPage = true
    },
    async addNewField (field) {
      this.$emit('addField', field)
      this.addNewFieldPage = false
    },
    useFieldInQuestionnaire (field) {
      if (field.inUse || this.suggestionsInUseInQuestionnaire.includes(field.name)) return
      const fieldIndex = this.templateData.questionnaire.fields.findIndex((f) => {
        if (f.fieldKey.toLowerCase() === field.fieldKey.toLowerCase()) {
          return f.customLabel === field.customLabel
        } else {
          return false
        }
      })
      if (fieldIndex !== -1) { // found
        return this.addToast({
          title: this.$t('warning|field_already_in_use'),
          color: 'white',
          snackbarColor: 'error'
        })
      }
      delete field.inUse
      this.addNewField(field)
    },
    useAllFieldsInQuestionnaire () {
      if (this.templateFieldTags && this.templateFieldTags.length) {
        const fieldsToUpload = this.templateFieldTags.filter(field => !field.inUse)
        this.$emit('addAllUnusedFields', fieldsToUpload)
      }
      // const updateQuestionnaire = {
      //   ...this.templateData.questionnaire
      // }
      // for (let i = 0; i < this.templateFieldTags.length; i++) {
      //   const field = this.templateFieldTags[i]
      //   if (!field.inUse && !this.options.inTemplate) {
      //     updateQuestionnaire.fields = [...updateQuestionnaire.fields, field]
      //     this.$emit('addField', {
      //       _id: this.templateData._id,
      //       workbenchId: this.workbench._id,
      //       payload: {
      //         ...this.templateData,
      //         questionnaire: updateQuestionnaire
      //       }
      //     })
      //   }
      //   if (!field.inUse && this.options.inTemplate) {
      //     updateQuestionnaire.fields = [...updateQuestionnaire.fields, field]
      //     this.$emit('addField', {
      //       _id: this.templateData._id,
      //       payload: {
      //         ...this.templateData,
      //         questionnaire: updateQuestionnaire
      //       }
      //     })
      //   }
      // }
    },
    async updateField (field) {
      const fieldIndex = this.templateData.questionnaire.fields.findIndex(
        f => f.id === field.id
      )
      const fieldIndexInTemplate = this.templateFieldTags.findIndex(
        f => f.id === field.id
      )
      if (fieldIndexInTemplate !== -1) {
        this.templateFieldTags[fieldIndexInTemplate] = field
        await this.$refs.editor.suggestionChangeInQuestionnaire(field)
        this.$emit('updateField', field)
      }
      if (fieldIndexInTemplate === -1 && fieldIndex !== -1) {
        this.templateData.questionnaire.fields[fieldIndex] = field
        this.$emit('updateField', field)
      }
    },
    async deleteField (field) {
      const res = await this.$dialog.confirm({
        title: this.$t('common|warning'),
        text: this.$t('common|are_you_sure_you_want_to_delete_this_resource')
      })
      if (!res || !field) return
      this.$emit('deleteField', {
        fieldToBeDeleted: field
      })
    },
    async saveTemplate () {
      const questionnaireFields = this.templateData.questionnaire.fields.map(
        q => {
          const key = q.customHashtag
            ? q.name
              .toLowerCase()
              .split(' ')
              .join('_')
            : q.key
          return {
            ...q,
            key
          }
        }
      )
      this.templateData.questionnaire.fields = questionnaireFields
      const editorTemplateData = this.$refs.editor.getData()
      if (
        !editorTemplateData ||
        !editorTemplateData.json ||
        !editorTemplateData.html
      ) { return }
      this.$emit('save', {
        _id: this.templateData._id,
        payload: {
          templateData: editorTemplateData,
          questionnaire: this.templateData.questionnaire
        }
      })
    },
    updateQuestionnaire (questionairre) {
      this.$emit('updateQuestionnaire', questionairre)
    },
    async saveSuggestion (_id, workbenchId, templateId, suggestion) {
      try {
        const payload = {
          suggestion: suggestion,
          saveCustomizedLabel: true
        }
        await this.updateCaseWorkbenchTemplateSuggestion({ _id, workbenchId, templateId, payload })
      } catch (error) {
        console.error(error, 'error')
      }
    },
    updateTemplateName (template, type) {
      this.$emit('update-template-name', template, type)
    },
    updateTempDescription (template) {
      this.$emit('update-template-description', template)
    },
    getFieldType (fieldType) {
      switch (fieldType) {
      case 'text-input':
        return this.$t('fields|text-input')
      case 'date-input':
        return this.$t('fields|date-input')
      case 'currency-input':
        return this.$t('fields|currency-input')
      case 'file-input':
        return this.$t('fields|file-input')
      default:
        return 'Yes/No Question (To be deleted)'
      }
    },
    getIconType (fieldType) {
      switch (fieldType) {
      case 'text-input':
        return 'mdi-format-text'
      case 'date-input':
        return 'mdi-calendar-range'
      case 'currency-input':
        return 'mdi-currency-eur'
      case 'file-input':
        return 'mdi-file-outline'
      default:
        return 'mdi-help-circle-outline'
      }
    },
    refreshTab () {
      this.componentKey += 1
    },
    setLanguage (lang) {
      this.selectedLanguage = lang
      localStorage.setItem('QuestionnaireFieldsLanguage', lang)
    },
    onAddNewFileInputSuggestion () {
      this.selectedFieldType = 'file-input'
      this.e6 = 2
      this.addNewFieldPage = true
      this.questionnaireRightSidePanelPage = false
    },
    applyData (node, data) {
      const foundIndex = this.nodeValuesToBeApplied.findIndex(obj => obj.node.attrs.id === node.attrs.id)
      if (foundIndex === -1) {
        node.attrs.fieldValue = data
        this.nodeValuesToBeApplied.push({ node, data: data })
      } else {
        this.nodeValuesToBeApplied[foundIndex].node.attrs.fieldValue = data
        this.nodeValuesToBeApplied[foundIndex].data = data
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .sticky-tabs {
    position: sticky;
    top: 48px;
    z-index: 1;
  }
  .active:hover {
    color: #234e79;
    .centered-icon {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
  .sticky {
    position: -webkit-sticky;
    position: sticky;
    top: 5px;
  }
  .container {
    display: flex;
    justify-content: space-around;
    align-items: flex-start;
  }
  .template-questionnaire-form {
    // display: flex;
    // flex-direction: column;
    position: relative;
    // height: 100%;
  }
  .template-questionnaire-form-case {
    // display: flex;
    // flex-direction: column;
    position: relative;
    // height: 100%;
  }
  .field-tags-container {
    display: flex;
    flex-wrap: wrap;
    .field-tag-add-box {
      display: flex;
      flex-wrap: wrap;
      border: 1px solid rgba(0,0,0,0.1);
      border-radius: 3px;
      margin: .3rem;
      overflow: hidden;
      font-size: .7rem;
      > div {
        display: flex;
        align-items: center;
        padding: 2px 3px;
        &:nth-of-type(2) {
          background-color: #91A6BC;
          color: white;
          cursor: pointer;
          &:hover {
            background-color: #5694d6;
          }
        }
      }
      &.used {
        > div {
          &:nth-of-type(2) {
            background-color: #2a9b9b;
            cursor: pointer;
            &:hover {
              background-color: #dd5a32;
            }
          }
        }
      }
    }
  }
</style>

<style scoped>
.v-tabs-items.full-height-tab  .v-window-item {
  height: 100% !important;
  width: 100% !important;
}
.toolbar {
  position: fixed;
  top: 88px;
  z-index: 1;
}
</style>
